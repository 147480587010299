$primary: #f26926;

/* MAIN COLOR */

$secondary: #3e7070;

/* SECONDARY COLOR */

$trm1: #CDCDCD;
$blk: #333;

/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */

$wht: #fafafa;
$border-radius: 0px;

/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */

$footerLinks: #fafafa;
html,
body {
    height: 100%;
    /*margin: 0;
  padding: 0;
  overflow: auto;*/
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
}

p,
ol,
ul {
    font-family: 'Gudea', sans-serif;
}

a {
    font-family: 'Podkova', serif;
}

h1 {
    font-family: 'Podkova', serif;
    font-size: 4em;
    text-transform: uppercase;
    @media (max-width: 1024px) {
        font-size: 3em;
    }
}

h2 {
    font-family: 'Podkova', serif;
    font-size: 3em;
    text-transform: uppercase;
}

h3 {
    font-family: 'Podkova', serif;
    font-size: 2.25em;
    text-transform: uppercase;
}

h4 {
    font-family: 'Podkova', serif;
    font-size: 1.75em;
    text-transform: uppercase;
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}


/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border-bottom: 2px solid $primary;
    border-radius: $border-radius;
    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 12px;
            display: flex;
            align-items: center;
            color: $blk;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
        }
    }
}

.navbar-right {
    margin-top: 10px;
    font-size: 2em;
    @media (max-width: 1024px) {
        font-size: 1.65em;
    }
}

.navbar-toggle {
    margin: 10px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 200px;
    padding: 0.5em;
}

@media(max-width: 767px) and (orientation: landscape) {
    .logo {
        max-width: 126px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 125px;
    }
    .navbar-toggle {
        margin-top: 10px;
    }
}

.top-pad {
    padding: 3em 0px;
    background: white;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}


/* ===========  HEADER =========== */

.header {
    background: url('../img/banner.jpg');
    background-repeat: no-repeat;
    background-position: 80% 20%;
    background-size: cover;
    @media (max-width: 1024px) {
        background-position: 63% 20%;
    }
}

.header-overlay {
    background: rgba(31, 31, 31, .5);
    padding: 250px 0px;
    @media (max-width: 1024px) {
        padding: 50px 0px;
        background: rgba(31, 31, 31, .75);
    }
}

.header h1 {
    color: $wht;
}

.header p {
    color: $wht;
    font-size: 1.5em;
    letter-spacing: 1px;
}


/*============ SECTION ONE ============== */

.section-one {
    padding: 50px 0px
}

.section-one h1 {
    color: $primary;
    text-shadow: 1px 1px $trm1;
    margin: 0px;
}

.section-one h2 {
    font-size: 2.5em;
    margin: 0px;
    color: $primary;
    text-shadow: 2px 2px $secondary;
}

.section-one p {
    color: $primary;
    font-size: 1.5em;
    letter-spacing: 1px;
    margin-bottom: 50px;
}

.section-one hr {
    margin: 8px;
}

.section-one .adjust {
    margin-top: 40px;
}


/*============ SECTION TWO ============== */

.section-two {
    overflow: hidden;
    background: $primary;
}

.section-two .bg-color {
    padding: 80px 20px;
}

.section-two h2 {
    color: $wht;
}

.section-two {
    color: $wht;
    font-size: 1.25em;
}


/*============ SECTION THREE ============== */

.section-three {
    padding: 25px 0px;
}

.section-three h1 {
    color: $primary;
    text-shadow: 1px 1px $secondary;
}

.section-three p {
    font-size: 1.25em;
}

.section-three i {
    font-size: 150px;
    display: block;
    margin: 0px auto;
    max-width: 225px;
    color: $primary;
}

.section-three p {
    margin-bottom: 75px;
}


/*============ SECTION FOUR ============== */

.section-four {
    background: url('../img/lower-banner.jpg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
}

.section-four-overlay {
    background: rgba(31, 31, 31, .5);
    padding: 150px 0px;
}

.section-four h1 {
    color: $wht;
    text-shadow: 1px 1px $secondary;
}

.section-four p {
    color: $wht;
    font-size: 1.25em;
}


/* ========= BUTTONS ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited {
    background-color: $primary;
    border-color: $wht;
    color: $wht;
    padding: 10px 20px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 5px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
}

.btn-download:hover {
    background-color: $secondary;
    color: $primary;
}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    // margin: 0 auto;
    max-width: 175px;
    margin-top: 15px;
}


/* ========= BUTTONS ============ */

.btn-download-2,
.btn-download-2:focus,
.btn-download-2:active,
.btn-download-2:visited {
    background-color: $primary;
    border-color: $wht;
    color: $wht;
    padding: 10px 20px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 5px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
}

.btn-download-2:hover {
    background-color: $secondary;
    color: $primary;
}

.btn-download-2 {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
    margin-top: 15px;
}


/*============ ET ICONS ============== */

@font-face {
    font-family: 'et-line';
    src: url('../fonts/et-line.eot');
    src: url('../fonts/et-line.eot?#iefix') format('embedded-opentype'), url('../fonts/et-line.woff') format('woff'), url('../fonts/et-line.ttf') format('truetype'), url('../fonts/et-line.svg#et-line') format('svg');
    font-weight: normal;
    font-style: normal;
}


/* Use the following CSS code if you want to use data attributes for inserting your icons */

[data-icon]:before {
    font-family: 'et-line';
    content: attr(data-icon);
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
}


/* Use the following CSS code if you want to have a class per icon */


/*
Instead of a list of all class selectors,
you can use the generic selector below, but it's slower:
[class*="icon-"] {
*/

.icon-mobile,
.icon-laptop,
.icon-desktop,
.icon-tablet,
.icon-phone,
.icon-document,
.icon-documents,
.icon-search,
.icon-clipboard,
.icon-newspaper,
.icon-notebook,
.icon-book-open,
.icon-browser,
.icon-calendar,
.icon-presentation,
.icon-picture,
.icon-pictures,
.icon-video,
.icon-camera,
.icon-printer,
.icon-toolbox,
.icon-briefcase,
.icon-wallet,
.icon-gift,
.icon-bargraph,
.icon-grid,
.icon-expand,
.icon-focus,
.icon-edit,
.icon-adjustments,
.icon-ribbon,
.icon-hourglass,
.icon-lock,
.icon-megaphone,
.icon-shield,
.icon-trophy,
.icon-flag,
.icon-map,
.icon-puzzle,
.icon-basket,
.icon-envelope,
.icon-streetsign,
.icon-telescope,
.icon-gears,
.icon-key,
.icon-paperclip,
.icon-attachment,
.icon-pricetags,
.icon-lightbulb,
.icon-layers,
.icon-pencil,
.icon-tools,
.icon-tools-2,
.icon-scissors,
.icon-paintbrush,
.icon-magnifying-glass,
.icon-circle-compass,
.icon-linegraph,
.icon-mic,
.icon-strategy,
.icon-beaker,
.icon-caution,
.icon-recycle,
.icon-anchor,
.icon-profile-male,
.icon-profile-female,
.icon-bike,
.icon-wine,
.icon-hotairballoon,
.icon-globe,
.icon-genius,
.icon-map-pin,
.icon-dial,
.icon-chat,
.icon-heart,
.icon-cloud,
.icon-upload,
.icon-download,
.icon-target,
.icon-hazardous,
.icon-piechart,
.icon-speedometer,
.icon-global,
.icon-compass,
.icon-lifesaver,
.icon-clock,
.icon-aperture,
.icon-quote,
.icon-scope,
.icon-alarmclock,
.icon-refresh,
.icon-happy,
.icon-sad,
.icon-facebook,
.icon-twitter,
.icon-googleplus,
.icon-rss,
.icon-tumblr,
.icon-linkedin,
.icon-dribbble {
    font-family: 'et-line';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
}

.icon-mobile:before {
    content: "\e000";
}

.icon-laptop:before {
    content: "\e001";
}

.icon-desktop:before {
    content: "\e002";
}

.icon-tablet:before {
    content: "\e003";
}

.icon-phone:before {
    content: "\e004";
}

.icon-document:before {
    content: "\e005";
}

.icon-documents:before {
    content: "\e006";
}

.icon-search:before {
    content: "\e007";
}

.icon-clipboard:before {
    content: "\e008";
}

.icon-newspaper:before {
    content: "\e009";
}

.icon-notebook:before {
    content: "\e00a";
}

.icon-book-open:before {
    content: "\e00b";
}

.icon-browser:before {
    content: "\e00c";
}

.icon-calendar:before {
    content: "\e00d";
}

.icon-presentation:before {
    content: "\e00e";
}

.icon-picture:before {
    content: "\e00f";
}

.icon-pictures:before {
    content: "\e010";
}

.icon-video:before {
    content: "\e011";
}

.icon-camera:before {
    content: "\e012";
}

.icon-printer:before {
    content: "\e013";
}

.icon-toolbox:before {
    content: "\e014";
}

.icon-briefcase:before {
    content: "\e015";
}

.icon-wallet:before {
    content: "\e016";
}

.icon-gift:before {
    content: "\e017";
}

.icon-bargraph:before {
    content: "\e018";
}

.icon-grid:before {
    content: "\e019";
}

.icon-expand:before {
    content: "\e01a";
}

.icon-focus:before {
    content: "\e01b";
}

.icon-edit:before {
    content: "\e01c";
}

.icon-adjustments:before {
    content: "\e01d";
}

.icon-ribbon:before {
    content: "\e01e";
}

.icon-hourglass:before {
    content: "\e01f";
}

.icon-lock:before {
    content: "\e020";
}

.icon-megaphone:before {
    content: "\e021";
}

.icon-shield:before {
    content: "\e022";
}

.icon-trophy:before {
    content: "\e023";
}

.icon-flag:before {
    content: "\e024";
}

.icon-map:before {
    content: "\e025";
}

.icon-puzzle:before {
    content: "\e026";
}

.icon-basket:before {
    content: "\e027";
}

.icon-envelope:before {
    content: "\e028";
}

.icon-streetsign:before {
    content: "\e029";
}

.icon-telescope:before {
    content: "\e02a";
}

.icon-gears:before {
    content: "\e02b";
}

.icon-key:before {
    content: "\e02c";
}

.icon-paperclip:before {
    content: "\e02d";
}

.icon-attachment:before {
    content: "\e02e";
}

.icon-pricetags:before {
    content: "\e02f";
}

.icon-lightbulb:before {
    content: "\e030";
}

.icon-layers:before {
    content: "\e031";
}

.icon-pencil:before {
    content: "\e032";
}

.icon-tools:before {
    content: "\e033";
}

.icon-tools-2:before {
    content: "\e034";
}

.icon-scissors:before {
    content: "\e035";
}

.icon-paintbrush:before {
    content: "\e036";
}

.icon-magnifying-glass:before {
    content: "\e037";
}

.icon-circle-compass:before {
    content: "\e038";
}

.icon-linegraph:before {
    content: "\e039";
}

.icon-mic:before {
    content: "\e03a";
}

.icon-strategy:before {
    content: "\e03b";
}

.icon-beaker:before {
    content: "\e03c";
}

.icon-caution:before {
    content: "\e03d";
}

.icon-recycle:before {
    content: "\e03e";
}

.icon-anchor:before {
    content: "\e03f";
}

.icon-profile-male:before {
    content: "\e040";
}

.icon-profile-female:before {
    content: "\e041";
}

.icon-bike:before {
    content: "\e042";
}

.icon-wine:before {
    content: "\e043";
}

.icon-hotairballoon:before {
    content: "\e044";
}

.icon-globe:before {
    content: "\e045";
}

.icon-genius:before {
    content: "\e046";
}

.icon-map-pin:before {
    content: "\e047";
}

.icon-dial:before {
    content: "\e048";
}

.icon-chat:before {
    content: "\e049";
}

.icon-heart:before {
    content: "\e04a";
}

.icon-cloud:before {
    content: "\e04b";
}

.icon-upload:before {
    content: "\e04c";
}

.icon-download:before {
    content: "\e04d";
}

.icon-target:before {
    content: "\e04e";
}

.icon-hazardous:before {
    content: "\e04f";
}

.icon-piechart:before {
    content: "\e050";
}

.icon-speedometer:before {
    content: "\e051";
}

.icon-global:before {
    content: "\e052";
}

.icon-compass:before {
    content: "\e053";
}

.icon-lifesaver:before {
    content: "\e054";
}

.icon-clock:before {
    content: "\e055";
}

.icon-aperture:before {
    content: "\e056";
}

.icon-quote:before {
    content: "\e057";
}

.icon-scope:before {
    content: "\e058";
}

.icon-alarmclock:before {
    content: "\e059";
}

.icon-refresh:before {
    content: "\e05a";
}

.icon-happy:before {
    content: "\e05b";
}

.icon-sad:before {
    content: "\e05c";
}

.icon-facebook:before {
    content: "\e05d";
}

.icon-twitter:before {
    content: "\e05e";
}

.icon-googleplus:before {
    content: "\e05f";
}

.icon-rss:before {
    content: "\e060";
}

.icon-tumblr:before {
    content: "\e061";
}

.icon-linkedin:before {
    content: "\e062";
}

.icon-dribbble:before {
    content: "\e063";
}